import React, { Component } from 'react'

class News extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render () {
        return <div>News</div>
    }
}

export default News