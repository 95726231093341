import React, { Component } from 'react';
import footer1 from '../../../assets/images/icon_buttom_logo.png'
import footer2 from '../../../assets/images/icon_call.png'
import footer3 from '../../../assets/images/icon_email.png'
import './style.scss'
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render () {
        return (
            <div className="footer">
                <ul className="content">
                    <li className="content-list">
                        <div className="list-left">
                            <img src={footer1} alt="footer" />
                        </div>
                        <div className="list-right">
                            <div className="name">深圳市研天科技有限公司</div>
                            <div className="txt">
                                广东省深圳市龙岗区坂田街道深度美创客园F栋2楼
                  </div>
                        </div>
                    </li>
                    <li className="content-list">
                        <div className="list-left">
                            <img src={footer2} alt="footer" />
                        </div>
                        <div className="list-right">
                            <div className="name">电子邮箱</div>
                            <div className="txt">yantiantech.@com</div>
                        </div>
                    </li>
                    <li className="content-list">
                        <div className="list-left">
                            <img src={footer3} alt="footer" />
                        </div>
                        <div className="list-right">
                            <div className="name">联系电话</div>
                            <div className="txt">0755-123456789</div>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Header;