/*
 * @Description: 文件简介
 * @Date: 2021-01-28 15:31:49
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 15:36:38
 */
import request from '../utils/http'

export function getFiles() {
  return request({
    url: '/getList/file',
    method: 'get',
  })
}

export function uploadFile(data) {
  return request({
    url: '/upload/file',
    data,
    method: 'post',
  })
}
