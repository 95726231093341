import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
// import {HashRouter as Router,Route,Link} from 'react-router-dom';
import Home from '../../home'
import Product from '../../product'
import Case from '../../case'
import News from '../../news'
import About from '../../about'

class Content extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render () {
        return (

            <div>
                <Switch>
                    <Route exact path='/' component={Home}></Route>
                    <Route exact path='/product' component={Product}></Route>
                    <Route exact path='/case' component={Case}></Route>
                    <Route exact path='/news' component={News}></Route>
                    <Route exact path='/about' component={About}></Route>
                </Switch>
            </div>)
    }
}

export default Content;