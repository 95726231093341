/*
 * @Description: 文件简介
 * @Date: 2021-01-28 15:40:11
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-29 17:43:27
 */
/*
 * @Description: 文件简介
 * @Date: 2021-01-28 15:40:04
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 15:40:35
 */
// import Mock from 'mockjs'
// const Random = Mock.Random
const productApi = [
  {
    url: '/product/items',
    type: 'post',
    response: (config) => {
      return {
        code: 200,
        data: [
          {
            icon: '',
            title: '便捷化使用',
          },
          {
            icon: '',
            title: '智能设备',
          },
          {
            icon: '',
            title: '科学处方',
          },
        ],
      }
    },
  },
  {
    url: '/product/mode',
    type: 'post',
    response: (config) => {
      return {
        code: 200,
        data: [
          {
            icon: '',
            title: '通过设备内置人脸识别与网络通讯模块，实现了用户无感登陆使用设备，自动为用户记录使用数据并保存于云端服务器，用户后续亦可通过小程序查看历史数据。此方案适用于校园等用户无法携带手机的使用场景。',
          },
          {
            icon: '',
            title: '智能设备',
          }
        ],
      }
    },
  },
]
export default productApi
