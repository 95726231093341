import React, { Component } from 'react';
import './style.scss'
// import sectionBg from '../../assets/images/product_section01.png'
class Product extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render () {
        return <section className='product'>
            <div className='section-1'>
                <h2 className='title'>室外智能健身房</h2>
                <p className='txt'>室外智能健身房是为室外运动场地、公园等研发的含体测设备、运动设备的智能体测运动方案，体测设备根据《国民体质测定标准》包含十余项体质测试功能，运动设备提供了运动消耗、时长统计等数据记录功能。在软件方面提供了用户端-室外智能健身小程序、管理端-室外智能管理平台，方便了用户使用设备及管理者对设备地点的日常管理。</p>
                {/* <div className='center-img'>
                    <img src={sectionBg} alt="" />
                </div> */}
                <ul className='icon-lists'>
                    <li className='list'>
                        <div></div>
                        <div>便捷化使用</div>
                    </li>
                </ul>
            </div>
            <div className='section-2'></div>
        </section>
    }
}

export default Product;