/*
 * @Description: 首页
 * @Date: 2020-12-21 11:36:09
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 15:37:43
 */
import React, { Component } from 'react'
import { Carousel, Rate, BackTop } from 'antd'
import CountUp from 'react-countup'
import './style.scss'
import { getFiles } from '../../api'
import infoImg01 from '../../assets/images/home_pic01.png'
import infoImg02 from '../../assets/images/home_pic02.png'
import infoImg03 from '../../assets/images/home_pic03.png'

import oa1 from '../../assets/images/oa_bg_01.png'
import oa2 from '../../assets/images/oa_bg_02.png'

import advantage01 from '../../assets/images/home_icon_advice.png'
import advantage02 from '../../assets/images/home_icon_intelligent.png'
import advantage03 from '../../assets/images/home_icon_use.png'

import achievementImg01 from '../../assets/images/home_icon_user.png'
import achievementImg02 from '../../assets/images/home_icon_equipment.png'
import achievementImg03 from '../../assets/images/home_icon_city.png'
import achievementImg04 from '../../assets/images/home_icon_custome.png'

import siteLeftImg0 from '../../assets/images/home_scenery_pic.png'
import siteCharts1 from '../../assets/images/site_icon01_echart.png'
import siteCharts2 from '../../assets/images/site_icon02_echart.png'
import siteCharts3 from '../../assets/images/site_icon03_echart.png'

const getFilesList = () => {
  getFiles().then(res => {
    console.log(res)
  })
}
const advantageOpt = [
  {
    id: '1',
    img: advantage01,
    txt: '便捷化使用',
  },
  {
    id: '2',
    img: advantage02,
    txt: '智能设备',
  },
  {
    id: '3',
    img: advantage03,
    txt: '科学运动处方',
  },
]
const achievementOpt = [
  {
    id: '1',
    img: achievementImg01,
    txt: '用户人数',
    num: 52146,
  },
  {
    id: '2',
    img: achievementImg02,
    txt: '设备数',
    num: 44321,
  },
  {
    id: '3',
    img: achievementImg03,
    txt: '城市数',
    num: 120,
  },
  {
    id: '4',
    img: achievementImg04,
    txt: '客户人数',
    num: 652105,
  },
]
const infoOpt = [
  {
    id: 1,
    img: infoImg01
  },
  {
    id: 2,
    img: infoImg02
  },
  {
    id: 3,
    img: infoImg03
  },
]
const siteOpt = [
  { id: 1, txt: '城市公园' },
  { id: 2, txt: '小区配套' },
  { id: 3, txt: '大学社区' },
]
const siteContentList = [
  {
    id: 1,
    name: '深圳湾运动公园',
    desc:
      '硬件研发团队。致力于智能体育运动方向的研发工作，为相关行业提供了室外智能健身房等智慧园区。',
    leftImg: siteLeftImg0,
    siteCharts01: siteCharts1,
    siteCharts02: siteCharts2,
    siteCharts03: siteCharts3,
  },
  {
    id: 2,
    name: '哈尔滨市儿童公园',
    desc:
      '硬件研发团队。致力于智能体育运动方向的研发工作，为相关行业提供了室外智能健身房等智慧园区。',
    leftImg: siteLeftImg0,
    siteCharts01: siteCharts1,
    siteCharts02: siteCharts2,
    siteCharts03: siteCharts3,
  },
  {
    id: 3,
    name: '新疆体育中心',
    desc:
      '硬件研发团队。致力于智能体育运动方向的研发工作，为相关行业提供了室外智能健身房等智慧园区。',
    leftImg: siteLeftImg0,
    siteCharts01: siteCharts1,
    siteCharts02: siteCharts2,
    siteCharts03: siteCharts3,
  },
]
const userEvalInfo = [
  { username: '程**', p: '随时都可以查看测试和运动数据，很方便', icon: 5 },
  { username: '钟**', p: '使用设备更加智能化了，需要扫码就可以使用', icon: 4 },
  { username: '李**', p: '带有人脸检测设备，更加方便智能了', icon: 4.5 },
  { username: '张**', p: '大数据分析，根据每个人的自身情况生成运动处方，我现在每天都在跟着处方锻炼', icon: 4.5 },
  { username: '王**', p: '竞赛车很有趣味性', icon: 5 },
  { username: '赖**', p: '后台数据可以实时监测，对我们很有帮助', icon: 5 },
]
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      totalNum: [6, 8, 3, 6, 9, 2],
      siteNavClassName: 'nav-list',
      curtSiteIndex: 0,
    }
  }
  toggleSite (index) {
    getFilesList()
    this.setState({
      curtSiteIndex: index,
    })
  }
  handleMouseOver (e, index) {

  }
  handleMouseLeave (e) {

  }
  render () {
    return (
      <div className="home">
        <BackTop duration={800} visibilityHeight={800} />
        <div className="carousel">
          <Carousel autoplay>
            <div className="carousel-list-1">
              <h2 className="carousel-title">研天科技室外智能健身房</h2>
              <p className="txt">累计总用户人数已达到</p>
              <ul className="total-num">
                {this.state.totalNum.map((item, index) => {
                  return (
                    <li className="list-num" key={index}>
                      <CountUp end={item} duration={2.5}></CountUp>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="carousel-list-2"></div>
            <div className="carousel-list-3"></div>
          </Carousel>
        </div>
        <div className="info-1">
          <h2 className="info-1-title">室外智能健身小程序</h2>
          <p className="txt">
            用户使用设备进行各项运动、体测功能后，可记录使用数据，获取运动处方、运动计划等
          </p>
          <ul className="info-img">
            {
              infoOpt.map((item, index) => {
                return <li className="info-img-list"
                  key={item.id}
                  onMouseEnter={e => this.handleMouseOver(e, index)}
                  onMouseLeave={e => this.handleMouseLeave(e, index)}
                >
                  <img src={item.img} alt="小程序图片简介" />
                </li>
              })
            }
          </ul>
        </div>
        <div className="info-2">
          <h2 className="info-2-title">室外智能健身平台</h2>
          <p className="txt">
            用户使用设备进行各项运动、体测功能后，可记录使用数据，获取运动处方、运动计划等
          </p>
          <ul className="info-img">
            <li className="info-img-list">
              <img className='img-1' src={oa1} alt="小程序图片简介" />
            </li>
            <li className="info-img-list">
              <img className='img-2' src={oa2} alt="小程序图片简介" />
            </li>
          </ul>
        </div>
        <div className="advantage">
          <div className="title">为什么选择研天？</div>
          <div className="txt">我们的优势</div>
          <ul className="advantage-img">
            {advantageOpt.map((item) => {
              return (
                <li key={item.id} className="advantage-img-list">
                  <div className="list-img">
                    <img src={item.img} alt="优势图片" />
                  </div>
                  <p className="list-txt">{item.txt}</p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="achievement">
          <div className="title">研天成就</div>
          <ul className="advantage-img">
            {achievementOpt.map((item) => {
              return (
                <li className="advantage-img-list" key={item.id}>
                  <div className="list-img">
                    <img src={item.img} alt="成就图片" />
                  </div>
                  <div className="list-txt">{item.txt}</div>
                  <div className="list-num">{item.num}</div>
                  <div className='list-bg'></div>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="site">
          <div className="title">业务场景</div>
          <ul className="site-nav">
            {siteOpt.map((item, index) => {
              // return <li className='nav-list' style={{ backgroundColor: this.state.curtSiteIndex === index ? "#192752" : '' }} key={item.id} onClick={(e) => this.toggleSite(e, index)}>{item.txt}</li>
              return (
                <li
                  className="nav-list"
                  style={
                    this.state.curtSiteIndex === index
                      ? { backgroundColor: '#192752', color: '#fff' }
                      : {}
                  }
                  key={item.id}
                  onClick={() => this.toggleSite(index)}
                >
                  {item.txt}
                </li>
              )
              // return <li className={this.state.siteNavClassName} key={item.id} onClick={(e) => this.toggleSite(e, index)}>{item.txt}</li>
            })}
          </ul>
          <ul className="site-content" >
            {siteContentList.map((item, index) => {
              if (this.state.curtSiteIndex === index) {
                return (
                  <li className="content-list" key={item.id} style={{ transform: this.state.curtSiteIndex === index ? "translate(0px, 0px)" : "translate(20px, 0px)" }}>
                    <div className="list-left">
                      <div className="left-img">
                        <img src={item.leftImg} alt="" />
                      </div>
                      <div className="location-name">{item.name}</div>
                      <p className="desc">{item.desc}</p>
                    </div>
                    <ul className="list-right">
                      <li style={{ fontSize: '24px' }}>数字成效</li>
                      <li className="echarts-list">
                        <img src={item.siteCharts01} alt="echarts" />
                        <img src={item.siteCharts01} alt="echarts" />
                      </li>
                      <li className="echarts-list">
                        <img src={item.siteCharts02} alt="echarts" />
                        <img src={item.siteCharts03} alt="echarts" />
                      </li>
                    </ul>
                  </li>
                )
              } else {
                return false
              }
            })}
          </ul>
        </div>
        <div className='evaluation'>
          <div className='evaluation-title'>用户评价</div>
          <div className='txt'>用户的建议帮助我们打磨更优秀的产品</div>
          <ul className='users'>
            {
              userEvalInfo.map((item, index) => {
                return <li className='users-item' key={item.username}>
                  <span className='username'>{item.username}：</span>
                  <span className='p'>{item.p}</span>
                  <span className='icon'>
                    <Rate allowHalf defaultValue={item.icon} disabled />
                  </span>
                </li>
              })
            }
          </ul>
        </div>
      </div>
    )
  }
}
export default Home
