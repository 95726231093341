/*
 * @Description: 文件简介
 * @Date: 2020-12-21 11:36:09
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 10:29:50
 */
import React, { Component } from 'react'
// 组件要以大写字母开头
import Layout from './views/layout'
import './App.scss'
import { HashRouter as Router } from 'react-router-dom'
class App extends Component {
  render() {
    return (
      <Router>
        <Layout />
      </Router>
    )
  }
}

export default App
