/*
 * @Description: 文件简介
 * @Date: 2021-01-28 15:26:31
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 17:53:09
 */
import Mock from 'mockjs'
import homeApi from './home'
import productApi from './product'
const mocks = [...homeApi, ...productApi]
for (const i of mocks) {
  Mock.mock(i.url, i.type, i.response)
}
