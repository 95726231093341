/*
 * @Description: 文件简介
 * @Date: 2020-12-21 11:36:09
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 15:33:20
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import './rem'
import './mock'
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
)
