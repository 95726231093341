import React, { Component } from 'react';
class Case extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render () {
        return (<div>Case</div>);
    }
}

export default Case;