/*
 * @Description: 文件简介
 * @Date: 2021-01-28 15:26:58
 * @Author: Chengxu
 * @LastEditors: Chengxu
 * @LastEditTime: 2021-01-28 15:27:45
 */
import axios from 'axios'
import { message } from 'antd'

const service = axios.create({
  // baseURL: '',
  timeout: 5000,
})

service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code !== 200) {
      message.error(res.message)
    } else {
      return res
    }
  },
  (error) => {
    message.error(error.message)
    return Promise.reject(error)
  }
)

export default service
